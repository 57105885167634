/* eslint-disable react/destructuring-assignment */
import React from "react";
import { Input, Button } from "./index";

const ForgotPasswordCard = function ForgotPasswordCard(props) {
  const handleKeyDown = (e) => {
    if (props.disabled || props.forgetPasswordRequestInProgress) return;
    if (e.key === "Enter") {
      props.sendResetLink();
    }
  };

  return (
    <div className="forgotPasswordCard">
      <div className="forgotPasswordHeader header24">Forgot password</div>
      <div className="forgotPasswordDesc tag14">
        Enter your <b>{props.app}</b> email and we&apos;ll send a verification
        code to help reset your password.
      </div>
      <Input
        placeHolder="Email id"
        value={props.email}
        onBlur={props.validateEmail}
        showError={props.emailError.error}
        errorMessage={props.emailError.errorMessage}
        onChange={(e) => props.handleEmail(e.target.value)}
        onKeyDown={handleKeyDown}
      />
      <Button
        disabled={props.disabled || props.forgetPasswordRequestInProgress}
        buttonText="Send Verification Code"
        primaryBtn
        onClick={props.sendResetLink}
        height="40"
      />
    </div>
  );
};

export default ForgotPasswordCard;

const defaultFunction = () => {};

ForgotPasswordCard.defaultProps = {
  email: "",
  validateEmail: defaultFunction,
  emailError: {},
  handleEmail: defaultFunction,
};
