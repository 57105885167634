/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-console */
import React from "react";
import ToggleShowPassword from "./ToggleShowPassword";
import { Input, Button } from "./index";
import googleIcon from "../assets/google.svg";
import microsoftIcon from "../assets/microsoft.svg";

const SignUpCard = function (props) {
  const _handleSubmit = () => {
    if (props.isEmailVerificationRequired) {
      props.verifyCode();
    } else {
      props.signUp();
    }
  };

  const _handleEmail = (value) => {
    if (!props.isInviteFlow && props.handleEmail) props.handleEmail(value);
  };

  return (
    <>
      <div className="signInButtonContainer no-user-select">
        Already have an account?
        <span
          className="signInButton button14"
          onClick={() => props.onSignInButtonClick()}
        >
          Sign In
        </span>
      </div>
      <div className="signUpCardContainer">
        <div className="signUpCard">
          <div className="signUpHeader header24">Welcome to {props.app}</div>
          <div className="signUpDesc tag14">
            Let’s get started by creating an account for you.
          </div>
          {!props.isEmailVerificationRequired && (
            <>
              <Button
                onClick={props.signInWithGoogle}
                buttonText="Sign up with Google"
                imageUrl={googleIcon}
                width="300px"
                height="44"
              />
              {props.microsoftAuthEnabled && (
                <Button
                  className="microsoftButton"
                  onClick={props.signInWithMicrosoft}
                  imageUrl={microsoftIcon}
                  buttonText="Sign up with Microsoft"
                  width="300px"
                  height="50"
                />
              )}
            </>
          )}
          {props.isInitialized &&
            (!props.orgConfig || !props.orgConfig.cognito_auth_disabled) && (
              <>
                <div className="orConatiner">
                  <div className="signInDivider">{null}</div>
                  <div className="orText button14">or</div>
                  <div className="signInDivider">{null}</div>
                </div>
                <Input
                  placeHolder="Email id"
                  value={props.email}
                  onChange={(e) => _handleEmail(e.target.value)}
                  disabled={props.isInviteFlow}
                  onBlur={props.isInviteFlow ? null : props.validateEmail}
                  showError={
                    props.isInviteFlow ? false : props.emailError.error
                  }
                  errorMessage={
                    props.isInviteFlow ? null : props.emailError.errorMessage
                  }
                />
                <Input
                  placeHolder="Password"
                  showError={props.passwordError.error}
                  errorMessage={props.passwordError.errorMessage}
                  type={props.type ? "password" : "text"}
                  value={props.password}
                  onBlur={props.validatePassword}
                  onChange={(e) => props.handlePassword(e.target.value)}
                  disabled={
                    !props.isConfirmationFlow &&
                    props.isEmailVerificationRequired
                  }
                  eyeIcon={() => (
                    <ToggleShowPassword
                      type={props.type}
                      setType={props.handleType}
                    />
                  )}
                />
                {props.isEmailVerificationRequired ? (
                  <>
                    <Input
                      placeHolder="Verification Code"
                      value={props.verificationCode}
                      type="text"
                      showError={props.verificationCodeError.error}
                      errorMessage={props.verificationCodeError.errorMessage}
                      onChange={(e) =>
                        props.handleVerificationCodeChange(e.target.value)
                      }
                    />
                    <Button
                      disabled={!props.verificationCode}
                      buttonText="Verify Account"
                      primaryBtn
                      onClick={() => _handleSubmit()}
                      height="40"
                    />
                  </>
                ) : (
                  <>
                    <Input
                      placeHolder="Name"
                      showError={props.nameError.error}
                      errorMessage={props.nameError.errorMessage}
                      value={props.name}
                      onBlur={props.validateName}
                      onChange={(e) => props.handleName(e.target.value)}
                      disabled={props.isEmailVerificationRequired}
                    />
                    <Button
                      disabled={props.disabled}
                      buttonText="Sign up"
                      primaryBtn
                      onClick={() => _handleSubmit()}
                      height="40"
                    />
                  </>
                )}
              </>
            )}
        </div>
        <div
          className={`privacy-policy${
            !props.isInitialized || props.orgConfig?.cognito_auth_disabled
              ? " marginTop20"
              : ""
          }`}
        >
          By creating an account, I accept and acknowledge the xto10x{" "}
          <a
            href="https://www.xto10x.com/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>{" "}
          and{" "}
          <a
            href="https://www.xto10x.com/security/terms-of-use"
            target="_blank"
            rel="noreferrer"
          >
            Terms of Use
          </a>
          .
        </div>
      </div>
    </>
  );
};

export default SignUpCard;
