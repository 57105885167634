import React, { useEffect } from "react";
import PropTypes from "prop-types";
import ToggleShowPassword from "./ToggleShowPassword";
import { Input, Button } from "./index";

const ResetPasswordCard = React.memo(function ResetPasswordCard(props) {
  useEffect(() => {
    const element = document.getElementById("verification");
    element.focus();
    element.innerText = "";
  }, []);

  const handleKeyDown = (e) => {
    if (props.disabled || props.resetPasswordRequestInProgress) return;
    if (e.key === "Enter") {
      props.setNewPassword();
    }
  };

  return (
    <div className="forgotPasswordCard">
      <div className="forgotPasswordHeader header24">Reset password</div>
      <div className="forgotPasswordDesc tag14">
        Create a new unique password to sign in
      </div>
      <Input placeHolder="Email id" value={props.email} disabled />
      <Input
        inputId="verification"
        placeHolder="Enter verification code here."
        value={props.verificationCode}
        onBlur={props.validateVerificationCode}
        type="text"
        showError={props.verificationCodeError.error}
        errorMessage={props.verificationCodeError.errorMessage}
        onChange={(e) => props.handleVerificationCode(e.target.value)}
        disableAutocomplete
      />
      <Input
        inputId="newPasswordInputField"
        placeHolder="New Password"
        value={props.password}
        onBlur={props.validatePassword}
        type={props.type ? "password" : "text"}
        showError={props.passwordError.error}
        errorMessage={props.passwordError.errorMessage}
        onChange={(e) => props.handlePassword(e.target.value)}
        onKeyDown={handleKeyDown}
        eyeIcon={() => (
          <ToggleShowPassword type={props.type} setType={props.handleType} />
        )}
        disableAutocomplete
      />
      <Button
        disabled={props.disabled || props.resetPasswordRequestInProgress}
        buttonText="Set Password"
        primaryBtn
        onClick={props.setNewPassword}
        height="40"
      />
    </div>
  );
});

export default ResetPasswordCard;

const defaultFunction = () => {};

ResetPasswordCard.propTypes = {
  email: PropTypes.string,
  password: PropTypes.string,
  validatePassword: PropTypes.func,
  handlePassword: PropTypes.func,
  setNewPassword: PropTypes.func,
};

ResetPasswordCard.defaultProps = {
  email: "",
  password: "",
  validatePassword: defaultFunction,
  handlePassword: defaultFunction,
  setNewPassword: defaultFunction,
};
