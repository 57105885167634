import React from 'react';
import Link from 'next/link';

const SignInLink = function (props) {
  return (
    <div className="signInButtonContainer no-user-select">
      Already have an account?{' '}
      <Link href={`/?redirect_url=${props.redirect_url}&app=${props.app}`}>
        <span className="signInButton button14">Sign in</span>
      </Link>
    </div>
  );
};

export default SignInLink;
