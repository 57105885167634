import React from 'react';
import Link from 'next/link';

const SignUpLink = function(props) {
  return (
    <div className="signUpButtonContainer no-user-select">
      Don&apos;t have an account?{' '}
      <Link href={`/sign-up-invite?redirect_url=${props.redirect_url}&app=${props.app}`}>
        <span className="signUpButton button14">Sign up</span>
      </Link>
    </div>
  );
}

export default SignUpLink;