/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import { dim } from '../styles/theme';

const StyledInput = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => props.width || '100%'};
  margin-bottom: 16px;

  .inputContainer {
    display: flex;
    flex-direction: column;
    justify-content: ${props =>
      props.img || (props.eyeIcon && props.hidePlaceHolder) ? 'center' : ''};
    border-radius: ${props => props.borderRadius || dim._4px};
    position: relative;
    background-color: ${props => {
      if (props.disabled) return props.theme.color.base_80;
      if (props.showError) return props.theme.color.red_100;
      if (props.backgroundColor) return props.backgroundColor;
      return props.theme.color.base_100;
    }};
    transition: background-color 0.5s ease-in;
    .eyeIcon {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .label10 {
    position: absolute;
    margin-top: ${dim._4px};
    color: ${props =>
      props.showError ? props.theme.color.tomato : props.theme.color.base_10};
    padding-left: ${dim._12px};
  }

  .inputText {
    background-color: inherit;
    border-radius: ${props => props.borderRadius || dim._4px};
    padding: ${props => {
      if (props.narrowPadding) return `${dim._4px} ${dim._16px}`;
      if (props.img)
        return `${dim._14px} ${dim._12px} ${dim._14px} ${dim._32px}`;
      if (props.eyeIcon)
        return `${dim._14px} ${dim._32px} ${dim._14px} ${dim._12px}`;
      if (props.value && !props.hidePlaceHolder)
        return `${dim._14px} ${dim._32px} ${dim._14px} ${dim._12px}`;
      return `${dim._14px} ${dim._12px}`;
    }};
    box-shadow: ${props =>
      props.showError
        ? '0 1px 2px 0 rgba(223, 46, 46, 0.25), 0 0 1px 0 rgba(223, 46, 46, 0)'
        : '0 1px 2px 0 rgba(9, 30, 66, 0.15), 0 0 1px 0 rgba(9, 30, 66, 0)'};
    border: 1px solid
      ${props =>
        props.showError ? props.theme.color.tomato : props.theme.color.base_70};
    color: ${props =>
      props.disabled
        ? props.theme.color.text_low_emphasis
        : props.theme.color.base_20};
    transition: border-color 0.2s ease-in;

    &:hover {
      border-color: ${props =>
        props.showError ? props.theme.color.tomato : '#adaeb5'};
      box-shadow: 0 1px 3px 0 rgba(9, 30, 66, 0.25),
        0 0 1px 0 rgba(9, 30, 66, 0);
    }

    :focus {
      border-color: ${props =>
        props.showError ? props.theme.color.tomato : props.theme.color.blue_50};
      box-shadow: 0 1px 3px 0 rgba(47, 83, 215, 0.25),
        0 0 1px 0 rgba(47, 83, 215, 0);

      ~ .label10 {
        color: ${props =>
          props.showError
            ? props.theme.color.tomato
            : props.theme.color.blue_50};
      }
    }
  }

  .errorMessage {
    margin-top: ${dim._4px};
    color: ${props => props.theme.color.tomato};
  }
`;

export default StyledInput;
