import React from 'react';
import StyledInput from './Input.styled';

const InputAreaTwoRow = React.memo(function InputAreaTwoRow(props) {
  return (
    <StyledInput {...props} hidden={props.hidden} state>
      <div className="inputContainer">
        <input
          id={props.inputId || ''}
          className={`inputText ${props.className ? props.className : 'tag14'}`}
          placeholder={props.placeHolder || 'placeholder'}
          onChange={props.onChange}
          value={props.value}
          max={props.max}
          min={props.min}
          minLength={props.minLength}
          maxLength={props.maxLength}
          type={props.type || 'text'}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={props.autoFocus}
          onBlur={props.onBlur ? props.onBlur : () => {}}
          disabled={props.disabled}
          autoComplete={props.disableAutocomplete ? 'off' : 'on'}
        />
        {props.value && !props.hidePlaceHolder ? (
          <span className="label10">{props.placeHolder}</span>
        ) : (
          ''
        )}
        {props.img ? props.img() : ''}
        {props.eyeIcon ? <div className="eyeIcon">{props.eyeIcon()}</div> : ''}
      </div>
      {props.showWordLimit && (
        <div>
          {props.value && props.value.length
            ? props.maxLength - props.value.length
            : props.maxLength}
        </div>
      )}
      {props.showError ? (
        <div className="errorMessage">
          {props.errorMessage || 'Invalid field!'}
        </div>
      ) : null}
    </StyledInput>
  );
});
export default InputAreaTwoRow;

InputAreaTwoRow.defaultProps = {
  placeHolder: 'Placeholder',
  showError: false,
  errorMessage: 'Error appeared',
  type: 'text'
};
