import React from "react";
import isEmail from "validator/lib/isEmail";
import ToggleShowPassword from "./ToggleShowPassword";
import { Input, Button } from "./index";
import ssoIcon from "../assets/sso.svg";
import googleIcon from "../assets/google.svg";
import microsoftIcon from "../assets/microsoft.svg";

const SignInCard = function SignInCard(props) {
  const { email, password } = props;

  const handleSubmit = () => {
    if (!(isEmail(email) && password.length)) return;
    if (props.isEmailVerificationRequired) {
      props.verifyCode();
    } else {
      props.signIn();
    }
  };

  const handleVerificationCodeKeyDown = (e) => {
    if (e.key === "Enter") {
      props.verifyCode();
    }
  };

  const handleKeyDown = (e) => {
    if (!(isEmail(email) && password.length)) return;
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <div className="signInCardContainer">
      <div className="signInCard">
        <div className="signInHeader header24">Welcome to {props.app}</div>
        {props.orgConfig?.sso_provider ? (
          <Button
            className="ssoLoginButton"
            onClick={() => props.ssoSignIn(props.orgConfig?.sso_provider)}
            imageUrl={ssoIcon}
            buttonText="Sign in with SSO"
            width="300px"
            height="50"
          />
        ) : (
          <>
            <Button
              onClick={props.signInWithGoogle}
              imageUrl={googleIcon}
              buttonText="Sign in with Google"
              width="300px"
              height="50"
            />
            {props.microsoftAuthEnabled && (
              <Button
                className="microsoftButton"
                onClick={props.signInWithMicrosoft}
                imageUrl={microsoftIcon}
                buttonText="Sign in with Microsoft"
                width="300px"
                height="50"
              />
            )}
            {props.isInitialized &&
              (!props.orgConfig || !props.orgConfig.cognito_auth_disabled) && (
                <>
                  <div className="orConatiner">
                    <div className="signInDivider">{null}</div>
                    <div className="orText button14">or</div>
                    <div className="signInDivider">{null}</div>
                  </div>
                  <Input
                    placeHolder="Email id"
                    showError={props.emailError.error}
                    errorMessage={props.emailError.errorMessage}
                    value={props.email}
                    onBlur={props.validateEmail}
                    onChange={(e) => props.handleEmail(e.target.value)}
                    disabled={props.isEmailVerificationRequired}
                    onKeyDown={handleKeyDown}
                  />
                  {props.isEmailVerificationRequired ? (
                    <>
                      <Input
                        placeHolder="Verification Code"
                        value={props.verificationCode}
                        type="text"
                        showError={props.verificationCodeError.error}
                        errorMessage={props.verificationCodeError.errorMessage}
                        onChange={(e) =>
                          props.handleVerificationCodeChange(e.target.value)
                        }
                        onKeyDown={handleVerificationCodeKeyDown}
                      />
                      <Button
                        onClick={props.verifyCode}
                        disabled={!props.verificationCode}
                        buttonText="Verify Account"
                        primaryBtn
                        height="40"
                      />
                    </>
                  ) : (
                    <>
                      <Input
                        placeHolder="Password"
                        showError={props.passwordError.error}
                        errorMessage={props.passwordError.errorMessage}
                        type={props.type ? "password" : "text"}
                        value={props.password}
                        onBlur={props.validatePassword}
                        onChange={(e) => props.handlePassword(e.target.value)}
                        onKeyDown={handleKeyDown}
                        eyeIcon={() => (
                          <ToggleShowPassword
                            type={props.type}
                            setType={props.handleType}
                          />
                        )}
                      />
                      <Button
                        disabled={props.disabled}
                        onClick={handleSubmit}
                        buttonText="Sign in"
                        primaryBtn
                        height="44"
                      />
                    </>
                  )}
                </>
              )}
          </>
        )}
      </div>
      <div
        className={`privacy-policy${
          !props.isInitialized || props.orgConfig?.cognito_auth_disabled
            ? " marginTop20"
            : ""
        }`}
      >
        By signing in, I accept and acknowledge the xto10x{" "}
        <a
          href="https://www.xto10x.com/privacy-policy"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </a>{" "}
        and{" "}
        <a
          href="https://www.xto10x.com/security/terms-of-use"
          target="_blank"
          rel="noreferrer"
        >
          Terms of Use
        </a>
        .
      </div>
      {props.isInitialized &&
        (!props.orgConfig ||
          (!props.orgConfig.cognito_auth_disabled &&
            !props.orgConfig.sso_provider)) && (
          <div
            className="button14 forgotPasswordButton"
            onClick={props.onForgotPasswordClick}
          >
            Forgot Password?
          </div>
        )}
    </div>
  );
};

export default SignInCard;
