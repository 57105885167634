"use client";

import React, { useState } from "react";
import StyledButton from "./Button.styled";

const Button = React.memo(function Button(props) {
  const _handleOnClick = () => {
    props.onClick();
  };

  return (
    <StyledButton
      {...props}
      onClick={_handleOnClick}
      className={props.className ? `${props.className} button14` : "button14"}
    >
      {
        // eslint-disable-next-line no-nested-ternary
        props.imageUrl ? (
          <>
            <img
              src={props.imageUrl}
              alt="imageUrl"
              id="image url"
              className="icon buttonImage"
            />
            <div>{props.buttonText}</div>
          </>
        ) : (
          props.buttonText
        )
      }
    </StyledButton>
  );
});

export default Button;

const defaultFunction = () => {};

Button.defaultProps = {
  onClick: defaultFunction,
  type: "default",
  isLoader: false,
};
