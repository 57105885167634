import React from 'react';

const ToggleShowPassword = function({ type, setType }) {
  const handleIconClick = () => setType(!type);

  return type ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      onClick={handleIconClick}>
      <path
        fill="#202124"
        fillRule="evenodd"
        d="M19.968 11.815C18.685 8.424 15.553 6.138 12 6c-3.553.138-6.685 2.424-7.968 5.815a.557.557 0 0 0 0 .37C5.315 15.576 8.447 17.862 12 18c3.553-.138 6.685-2.424 7.968-5.815a.557.557 0 0 0 0-.37zM12 17c-2.87 0-5.901-2.183-7-5 1.099-2.817 4.13-5 7-5s5.901 2.183 7 5c-1.099 2.817-4.13 5-7 5zm0-8a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm0 5a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
        clipRule="evenodd"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      onClick={handleIconClick}>
      <path
        fill="#202124"
        fillRule="evenodd"
        d="M19.968 11.815C18.685 8.424 15.553 6.138 12 6a8.74 8.74 0 0 0-3.765 1.017l.74.74C9.94 7.275 10.98 7 12 7c2.87 0 5.901 2.183 7 5-.498 1.276-1.392 2.422-2.488 3.294l.715.715a9.089 9.089 0 0 0 2.741-3.824.557.557 0 0 0 0-.37zM6.745 8.013 6 7.268l.668-.668L18 17.932l-.668.668-1.6-1.6A8.74 8.74 0 0 1 12 18c-3.553-.138-6.685-2.424-7.968-5.815a.557.557 0 0 1 0-.37 9.09 9.09 0 0 1 2.713-3.802zm8.247 8.247c-.957.47-1.985.74-2.992.74-2.87 0-5.901-2.183-7-5 .494-1.265 1.377-2.402 2.46-3.272l1.881 1.881a3 3 0 0 0 4.05 4.05l1.6 1.6zm-2.362-2.362-2.529-2.529a2 2 0 0 0 2.529 2.529zM14 12a2 2 0 0 1-.115.668l.756.756a3 3 0 0 0-4.065-4.065l.756.755A2 2 0 0 1 14 12z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default ToggleShowPassword;
